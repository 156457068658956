<template>
  <div class="competition-footer">
    <div class="content">
      <div class="link anime">
        <a href="" v-for="item in linkList" :key="item.id" class="item">
          <img :src="item.image" alt="">
        </a>
      </div>
    </div>
    <f-link />
  </div>
</template>
<script>
import fLink from "@/components/f-link";
export default {
  components: {
    fLink,
  },
  data() {
    return {
      linkList: [
        { id: 1, image: require("@/assets/images/logos/01.jpg") },
        { id: 2, image: require("@/assets/images/logos/02.jpg") },
        { id: 3, image: require("@/assets/images/logos/03.jpg") },
        { id: 4, image: require("@/assets/images/logos/04.jpg") },
        { id: 5, image: require("@/assets/images/logos/05.jpg") },
        { id: 6, image: require("@/assets/images/logos/06.jpg") },
        { id: 7, image: require("@/assets/images/logos/07.jpg") },
        { id: 8, image: require("@/assets/images/logos/08.jpg") },
        { id: 9, image: require("@/assets/images/logos/09.jpg") },
        { id: 10, image: require("@/assets/images/logos/10.jpg") },
        { id: 11, image: require("@/assets/images/logos/11.jpg") },
        { id: 12, image: require("@/assets/images/logos/12.jpg") },
        { id: 13, image: require("@/assets/images/logos/13.jpg") },
        { id: 14, image: require("@/assets/images/logos/14.jpg") },
        { id: 15, image: require("@/assets/images/logos/15.jpg") }
      ],
    };
  },
};
</script>
<style lang="scss">
.competition-footer {
  padding: 5vw 0 5vw;
  .content {
    .link {
      display: flex;
      flex-wrap: wrap;
      .item {
        width: calc(90% / 5);
        background-color: white;
        padding: 0 3%;
        box-sizing: border-box;
        margin: 1% 1%;
        height: 7vw;
        display: block;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          display: block;
          max-width: 100%;
          max-height: 90%;
          border-radius: 50%;
        }
      }
    }
  }
  .f-link {
    background-color: rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.2);
    a.item {
      color: white;
      &:hover {
        color: $c2;
      }
    }
  }
}
</style>
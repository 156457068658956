
let $storage = {
  //存入
  setItem: function (key, value, time) {
    //记录当前时间，好在取出时做比较
    var curTime = new Date().getTime();
    //存入值、过期时间、当前时间
    localStorage.setItem(key, JSON.stringify({
      data: value,
      time: time,
      curTime: curTime
    }));
  },
  //取出
  getItem: function (key) {
    //获取值
    var data = localStorage.getItem(key);
    try {
      //转json对象
      var dataObj = JSON.parse(data);
      if(dataObj.time){
        if (new Date().getTime() - dataObj.curTime > dataObj.time) {
          console.log('信息已过期');
          return null;
        } else {
          return dataObj.data;
        }
      }else{
        return dataObj.data;
      }
    } catch (error) {
      //旧数据报错后直接输出
      return data;
    }
  },
  //删除
  removeItem: function (key) {
    //获取
    var data = localStorage.getItem(key);
    if (data) {
      //是否存在，存在则删除
      const data = localStorage;
      delete data[key];
      delete data[`${key}__expires__`];
    }
  }
}


export default $storage;
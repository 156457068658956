<template>
  <div id="app">
    <headers v-show="isOver" />
    <loading v-show="!isOver" />
    <router-view />
    <footers v-show="isOver" />
  </div>
</template>
<script>
import headers from "@/components/public/headers";
import footers from "@/components/public/footers";
import loading from "@/components/loading";
export default {
  name: "Layout",
  components: {
    headers,
    footers,
    loading,
  },
  data() {
    return {
      isOver: false,
    };
  },
  methods: {
    finish() {
      this.isOver = true;
    },
  },
};
</script>
<style lang="scss">
@import "@/assets/styles/init.scss";
</style>

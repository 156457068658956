<template>
  <div class="footer">
    <div class="content">
      <div class="list flex_b">
        <div class="item" v-for="item in menuList" :key="item.id">
          <h3>{{ item.title }}</h3>
          <p v-for="items in item.list" :key="items.title">{{ items.title }}</p>
        </div>
        <div class="code">
          <h3>全国咨询热线</h3>
          <a href="tel:15601232025">15601232025</a>
          <div><img src="/code.png" alt="" /></div>
        </div>
      </div>
      <div class="text">
        网站备案号(
        <a href="" target="_blank">吉ICP备2021000343号 </a>
        )
        <span class="text-primary">
          增值电信业务经营许可证（信息服务业务|在线数据处理与交易处理业务） (
          <a href="http://www.miit.gov.cn/" target="_blank"> 吉B2-20210062 </a>
          )
        </span>
      </div>
    </div>
    <f-link v-if="!notLink" />
  </div>
</template>
<script>
import fLink from "@/components/f-link";
export default {
  components: {
    fLink,
  },
  props: {
    notLink: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      menuList: [
        {
          id: 1,
          title: "公司",
          list: [{ id: 11, title: "关于我们" }],
        },
        {
          id: 2,
          title: "帮助与支持",
          list: [
            { id: 21, title: "知识中心" },
            { id: 22, title: "联系我们" },
            { id: 23, title: "高级支持" },
            { id: 24, title: "赞助" },
            { id: 25, title: "定制开发" },
          ],
        },
        {
          id: 3,
          title: "法律",
          list: [
            { id: 31, title: "条款和条件" },
            { id: 32, title: "隐私政策" },
          ],
        },
        {
          id: 4,
          title: "资源",
          list: [
            { id: 41, title: "免费赠品" },
            { id: 42, title: "溢价" },
            { id: 42, title: "获得优惠券" },
          ],
        },
      ],
    };
  },
};
</script>
<style lang="scss">
.footer {
  background: url(../../assets/images/public/footer_back.jpg) no-repeat center;
  background-size: cover;
  .content {
    .list {
      padding-top: 70px;
      .item {
        width: 18%;
        h3 {
          font-size: 20px;
          font-weight: 500;
          color: white;
        }
        p {
          margin-top: 15px;
          color: #666666;
          font-size: 14px;
          &:first-child {
            margin-top: 25px;
          }
        }
      }
      .code {
        width: 28%;
        text-align: right;
        h3 {
          font-size: 16px;
          font-weight: 500;
          color: white;
        }
        a {
          display: block;
          margin: 25px 0;
          font-size: 30px;
          line-height: 1;
          color: white;
        }
        p {
          font-size: 14px;
          line-height: 25px;
          color: white;
        }
        div {
          width: 95px;
          display: inline-block;
          margin-top: 20px;
          img {
            width: 100%;
          }
        }
      }
    }
    .text {
      border-top: 1px solid #666666;
      margin-top: 50px;
      padding: 50px 0;
      text-align: center;
      font-size: 14px;
      color: #888888;
      a {
        color: #888888;
      }
    }
  }
}
@media (max-width:1000px) {
  .footer {
  background: url(../../assets/images/public/footer_back.jpg) no-repeat center;
  background-size: cover;
  .content {
    .list {
      padding-top: 5vw;
      flex-wrap: wrap;
      .item {
        width: 25%;
        h3 {
          font-size: 4vw;
        }
        p {
          margin-top: 2vw;
          color: #666666;
          font-size: 3vw;
          &:first-child {
            margin-top: 2vw;
          }
        }
      }
      .code {
        width: 50%;
        text-align: left;
        margin-top: 5vw;
        h3 {
          font-size: 4vw;
          font-weight: 500;
          color: white;
        }
        a {
          display: block;
          margin: 2vw 0;
          font-size: 5vw;
          line-height: 1;
          color: white;
        }
        p {
          font-size: 3vw;
          line-height: 6vw;
          color: white;
        }
        div {
          width: 25vw;
          display: inline-block;
          margin-top: 2vw;
          img {
            width: 100%;
          }
        }
      }
    }
    .text {
      border-top: 1px solid #666666;
      margin-top: 2vw;
      padding: 5vw 0;
      text-align: center;
      font-size: 3vw;
      color: #888888;
      a {
        color: #888888;
      }
    }
  }
}
}
</style>
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import '@/assets/theme/index.css';
Vue.use(ElementUI);
Vue.config.productionTip = false
router.beforeEach((to, form, next) => {
  document.title = to.name;
  next();
});
// 请求
import {
  service,
  staticService
} from '@/common/api'
Vue.prototype.$api = service;
Vue.prototype.$sapi = staticService;
//本地储存
import storage from './common/storage'
Vue.prototype.$storage = storage;
//公用方法
import common from './common/common';
Vue.prototype.$common = common;
import echarts from 'echarts';
import 'echarts/map/js/china.js';
Vue.prototype.$echarts = echarts;

// 测试账号密码
console.log(common.encode('yaoxu'),common.encode('123456'));

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
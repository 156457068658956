<template>
  <div id="app" class="competition-main">
    <headers v-show="isOver" />
    <loading v-show="!isOver" />
    <router-view />
    <footers v-show="isOver" />
    <footers2 :notLink="true" v-show="isOver" />
  </div>
</template>
<script>
import headers from "@/components/competition/header";
import footers from "@/components/competition/footer";
import footers2 from "@/components/public/footers";
import loading from "@/components/loading";
export default {
  name: "Layout",
  components: {
    headers,
    footers,
    loading,
    footers2
  },
  data() {
    return {
      isOver: false,
    };
  },
  methods: {
    finish() {
      this.isOver = true;
    },
  },
};
</script>
<style lang="scss">
@import "@/assets/styles/init.scss";
body {
  .competition-main {
    background-color: $c3;
    min-height: 100vh;
  }
}
</style>

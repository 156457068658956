import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
import Layout from '@/views/public/index.vue'
import Layout2 from '@/views/competition/index.vue'
const routes = [{
    path: '/',
    name: '首页',
    component: Layout,
    children: [{
      path: 'home',
      name: '首页-易造工业互联网',
      component: () => import('@/views/home/index.vue'),
    }, {
      path: '',
      name: '在线制造平台-易造工业互联网',
      component: () => import('@/views/make/index.vue')
    }, {
      path: 'supplier/list',
      name: '供应商列表-易造工业互联网',
      component: () => import('@/views/supplier/list.vue')
    }, {
      path: 'service/list',
      name: '招标大厅-易造工业互联网',
      component: () => import('@/views/service/list.vue')
    }, {
      path: 'service/detail',
      name: '需求详情-易造工业互联网',
      component: () => import('@/views/service/detail.vue')
    }, {
      path: 'servers/list',
      name: '热门服务-易造工业互联网',
      component: () => import('@/views/servers/list.vue')
    }, {
      path: 'design',
      name: '工业设计平台-易造工业互联网',
      component: () => import('@/views/design/index.vue')
    }, {
      path: 'solution',
      name: '行业解决方案-易造工业互联网',
      component: () => import('@/views/solution/index.vue')
    }, {
      path: 'support',
      name: '服务与支持-易造工业互联网',
      component: () => import('@/views/support/index.vue')
    }]
  },{
    path: '/competition',
    name: '大赛',
    component: Layout2,
    children: [{
      path: '',
      name: '大赛首页-竞赛在线-易造工业互联网',
      component: () => import('@/views/competition/home.vue'),
    },{
      path: 'news',
      name: '赛事动态-竞赛在线-易造工业互联网',
      component: () => import('@/views/competition/news.vue'),
    },{
      path: 'show',
      name: '作品展示-竞赛在线-易造工业互联网',
      component: () => import('@/views/competition/show.vue'),
    },{
      path: 'specialist',
      name: '评审专家团-竞赛在线-易造工业互联网',
      component: () => import('@/views/competition/specialist.vue'),
    },{
      path: 'flow',
      name: '报名流程-竞赛在线-易造工业互联网',
      component: () => import('@/views/competition/flow.vue'),
    },{
      path: 'showDetail',
      name: '作品详情-竞赛在线-易造工业互联网',
      component: () => import('@/views/competition/showDetail.vue'),
    },{
      path: 'newsDetail',
      name: '动态详情-竞赛在线-易造工业互联网',
      component: () => import('@/views/competition/newsDetail.vue'),
    }]
  },
  {
    path: '/login',
    name: '登录-易造工业互联网',
    component: () => import('@/views/login/index.vue'),
  },
  {
    path: '/register',
    name: '注册-易造工业互联网',
    component: () => import('@/views/login/register.vue'),
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
<template>
  <div :class="['header', $route.fullPath == '/home' ? 'on' : '']">
    <div v-if="!isWap" :class="['header-main', isDown ? 'on' : '']">
      <div class="bj"></div>
      <div class="content anime flex_b flex_align_c">
        <div class="logo">
          <a href="/">
            <img
              v-if="this.$route.fullPath == '/home'"
              src="@/assets/images/public/logo.png"
              alt=""
            />
            <img v-else src="@/assets/images/public/logo2.png" alt="" />
          </a>
        </div>
        <div class="location">
          <el-popover placement="bottom" width="300" trigger="click">
            <el-row :gutter="5" class="location-list">
              <el-col :span="6" v-for="item in locationList" :key="item">
                <div
                  :class="['item', location == item ? 'on' : '']"
                  @click="location = item"
                >
                  {{ item }}
                </div>
              </el-col>
            </el-row>
            <div slot="reference" class="text">
              {{ location }} <span class="el-icon-caret-bottom"></span>
            </div>
          </el-popover>
        </div>
        <div class="menu-and-search">
          <div class="menu flex">
            <div
              :class="['item', hasActive(item.url) ? 'on' : '']"
              v-for="item in menuList"
              :key="item.id"
            >
              <a :href="item.url">{{ item.title }}</a>
            </div>
          </div>
          <div class="search">
            <el-input
              placeholder="关键词搜索"
              v-model="keyword"
              class="input-with-select"
            >
              <el-button slot="append" icon="el-icon-search"></el-button>
            </el-input>
          </div>
        </div>
        <div class="login flex flex_align_c" v-if="hasLogin == 0">
          <span class="el-icon-user-solid"></span>
          <div>
            <a :href="$store.state.loginUrl">登录</a> /
            <a :href="$store.state.registerUrl">注册</a>
          </div>
        </div>
        <el-dropdown class="f-login" v-if="hasLogin == 1">
          <span class="el-dropdown-link flex flex_align_c">
            {{ role == 1 ? "客户中心" : "工厂中心"
            }}<span class="el-icon-caret-bottom el-icon--right"></span>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item>
              <a href="/a/login?yzType=2">个人中心</a>
            </el-dropdown-item>
            <el-dropdown-item>
              <a href="/a/index?yzType=2#/a/shop/yzTaskOrder/list#需求订单"
                >我的订单</a
              >
            </el-dropdown-item>
            <el-dropdown-item>
              <a href="/a/index?yzType=2#/a/shop/yzTaskEvaluation/list#需求估价"
                >我的报价</a
              >
            </el-dropdown-item>
            <el-dropdown-item divided
              ><a href="/a/logout?yzType=1">退出登录</a></el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <div v-if="isWap" class="header-main-m">
      <div class="bj"></div>
      <div class="show">
        <div class="l">
          <div class="logo">
            <a href="/">
              <img src="@/assets/images/public/logo.png" alt="" />
            </a>
          </div>
          <!-- <div class="location">
            <el-popover placement="bottom" width="300" trigger="click">
              <el-row :gutter="5" class="location-list">
                <el-col :span="6" v-for="item in locationList" :key="item">
                  <div
                    :class="['item', location == item ? 'on' : '']"
                    @click="location = item"
                  >
                    {{ item }}
                  </div>
                </el-col>
              </el-row>
              <div slot="reference" class="text">
                {{ location }} <span class="el-icon-caret-bottom"></span>
              </div>
            </el-popover>
          </div> -->
        </div>
        <div class="menu-btn" @click="isOpen = true">
          <i class="iconfont icon-ego-menu"></i>
          <i class="iconfont icon-cha1"></i>
        </div>
      </div>
      <div :class="['menu', isOpen ? 'on' : '']">
        <i class="iconfont icon-cha1" @click="isOpen = false"></i>
        <div class="login" v-if="hasLogin == 0">
          <span class="el-icon-user-solid"></span>
          <div>
            <a :href="$store.state.loginUrl">登录</a> /
            <a :href="$store.state.registerUrl">注册</a>
          </div>
        </div>
        <div class="user-menu" v-if="hasLogin == 1">
          <a href="/a/login?yzType=2">个人中心</a>
          <a href="/a/index?yzType=2#/a/shop/yzTaskOrder/list#需求订单">
            我的订单
          </a>
          <a href="/a/logout?yzType=1">退出登录</a>
        </div>
        <div class="list">
          <div
            :class="['item', hasActive(item.url) ? 'on' : '']"
            v-for="item in menuList"
            :key="item.id"
          >
            <a :href="item.url">{{ item.title }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isOpen: false,
      location: "北京站",
      locationList: ["北京站", "上海站", "深圳站", "广州站", "杭州站"],
      menuList: [
        { id: 1, title: "首页", url: "/home" },
        { id: 2, title: "在线制造平台", url: "/" },
        { id: 3, title: "工业设计平台", url: "/design" },
        { id: 4, title: "行业解决方案", url: "/solution" },
        { id: 5, title: "服务与支持", url: "/support" },
        { id: 6, title: "竞赛在线", url: "/competition" },
      ],
      keyword: "",
      hasLogin: false,
      isDown: false,
      userInfo: {},
      title: "客户中心",
      role: 1,
      isWap: false,
    };
  },
  methods: {
    goUrl(item) {
      this.$router.push({ path: item.url });
    },
    hasActive(url) {
      if (this.$route.fullPath == "/") {
        return this.$route.fullPath == url;
      } else if (url != "/") {
        return this.$route.fullPath.indexOf(url) >= 0;
      }
    },
  },
  mounted() {
    this.isWap = $(window).width() < 1000;
    this.$api.get("/api/v1/loginUserCheck").then((res) => {
      this.hasLogin = res.code;
      if (res.data) {
        this.userInfo = res.data;
        if (res.data.roleCode) {
          if (res.data.roleCode.indexOf("member") >= 0) {
            //客户
            this.role = 1;
          } else if (res.data.roleCode.indexOf("shoper") >= 0) {
            //服务商
            this.role = 2;
          } else if (
            res.data.roleCode.indexOf("member") >= 0 &&
            res.data.roleCode.indexOf("shoper") >= 0
          ) {
            //多重角色
            this.role = 3;
          }
        }
        this.userInfo.roleType = this.role;
        this.$store.commit("setUserInfo", this.userInfo);
        this.$storage.setItem("userInfo", this.userInfo);
      }
    });
    this.$store.commit("anime");
    let htmlObj = document.getElementsByTagName("html")[0];
    window.onscroll = () => {
      this.$store.commit("anime");
      if (htmlObj.scrollTop > 50) {
        this.isDown = true;
      } else {
        this.isDown = false;
      }
    };
  },
};
</script>
<style lang="scss">
@import "@/assets/scss/headers.scss";
</style>